import { Api } from '@api/Api'

export const fetchDeleteAdditionPayment = async (id) => {
  const { data } = await Api.delete(`/additional-payments/${id}/delete/`)
  return data
}

export const fetchUpdateAdditionPayment = async ({ id, body }) => {
  const { data } = await Api.put(`/additional-payments/${id}/update/`, body)
  return data
}

export const fetchCreateAdditionPayment = async (body) => {
  const { data } = await Api.post(`/additional-payments/create/`, body)
  return data
}

export const downloadAdditionalPaymentCsv = async (params) => {
  const { data } = await Api.get('/additional-payments/reports/', {
    params,
    responseType: 'blob',
  })

  return data
}
