import 'bootstrap/dist/css/bootstrap.min.css'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { emptyCircle, infoIcon, success } from '@core/icons/icons'
import { getPayrollList } from '@services/payroll.service'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const ProgressBar = styled('div')`
  width: ${(p) => `${p.procentage}`};
  background: ${(p) => (p.active ? '#04c53a' : '#EBEBEB')};
  height: 1px;
  display: block;
`

const months = {
  August: {
    from: 19,
    to: 25,
  },
  September: {
    from: 17,
    to: 26,
  },
  October: {
    from: 16,
    to: 25,
  },
  November: {
    from: 18,
    to: 27,
  },
  December: {
    from: 16,
    to: 27,
  },
}

function addWorkingDays(startDate, days) {
  const currentDate = new Date(startDate)
  let addedDays = 0

  while (addedDays < days) {
    currentDate.setDate(currentDate.getDate() + 1)

    // Check if the current day is a weekday (Monday to Friday) and not a holiday
    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      // eslint-disable-next-line no-plusplus
      addedDays++
    }
  }

  return currentDate
}

function calculateElapsedPercentage(startDate, endDate) {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const now = new Date()

  // Ensure that the current date is within the range
  if (now < start) return 0
  if (now > end) return 100

  const totalDuration = end - start
  const elapsedDuration = now - start

  const percentage = (elapsedDuration / totalDuration) * 100

  return percentage.toFixed(2) // Return percentage with 2 decimal places
}

const CalendarStepper = () => {
  const history = useHistory()
  const [payrollsData, setPayrollsData] = useState([])

  const today = new Date()
  const currentMonthFirstDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  )
  const monthString = moment(today).format('MMMM')
  const nextMonthFirstDate = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    1
  )
  const thirdWorkDays = addWorkingDays(currentMonthFirstDate, 3)
  const endDueDate = addWorkingDays(currentMonthFirstDate, 8)
  const weekDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    months[monthString].from
  )
  const cutOffDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    months[monthString].to
  )
  const { data } = useQuery(['payrolls'], {
    queryFn: () => getPayrollList({ limit: 100000, page: 1 }),
    keepPreviousData: true,
  })

  useEffect(() => {
    if (data) {
      setPayrollsData(data?.current)
    }
  }, [data])

  return (
    <div
      className="d-flex flex-column overview-page-stats mb-3"
      style={{ border: '1px solid green' }}
    >
      <div className="d-flex align-items-center mb-3">
        <Typography className="semibold__16 mr-2">Payroll Timeline</Typography>
        <Typography className="semibold__16 color_primary mr-2">
          {moment(today).format('MMMM YYYY')}
        </Typography>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              The dates below are to guarantee employees get paid on time; if
              you encounter any special difficulty please reach out to our team.
              Please note if new team member joins later this month, payroll
              dates might be impacted but stay assured that our team will guide
              you through.
            </Tooltip>
          }
        >
          <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
            <Icon icon={infoIcon} style={{ fill: '#878787' }} />
          </span>
        </OverlayTrigger>
      </div>

      <div className="d-flex">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Icon
              icon={
                calculateElapsedPercentage(
                  currentMonthFirstDate,
                  thirdWorkDays
                ) === 100
                  ? success
                  : emptyCircle
              }
            />
            <ProgressBar
              procentage={`${calculateElapsedPercentage(
                currentMonthFirstDate,
                thirdWorkDays
              )}%`}
              active
            />
            <ProgressBar
              procentage={`${
                100 -
                calculateElapsedPercentage(currentMonthFirstDate, thirdWorkDays)
              }%`}
            />
          </div>
          <div className="mt-2 pr-4">
            <Typography className="semibold__16">
              Additional payments & expenses deadline
            </Typography>
            <Typography className="text_medium__14 mt-2">
              {currentMonthFirstDate.getDate()}{' '}
              {moment(currentMonthFirstDate).format('ddd')}
            </Typography>
            <Typography className="text_light__12 mt-2 color_grey">
              Entry after deadline can only go into{' '}
              {moment(currentMonthFirstDate).format('MMMM')} payroll
            </Typography>
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Icon
              icon={
                calculateElapsedPercentage(endDueDate, weekDate) === 100
                  ? success
                  : emptyCircle
              }
            />
            <ProgressBar
              procentage={`${calculateElapsedPercentage(
                endDueDate,
                weekDate
              )}%`}
              active
            />
            <ProgressBar
              procentage={`${
                100 - calculateElapsedPercentage(endDueDate, weekDate)
              }%`}
            />
          </div>
          <div className="mt-2 pr-4">
            <Typography className="semibold__16">
              Payroll ready for approval
            </Typography>
            <Typography className="text_medium__14 mt-2">
              Between {thirdWorkDays.getDate()}{' '}
              {moment(thirdWorkDays).format('ddd')} and {weekDate.getDate()}{' '}
              {moment(weekDate).format('ddd')}
            </Typography>
            <Typography className="text_light__12 color_grey mt-2">
              Payroll is ready for approval from Day #; non-approved payroll
              will automatically be approved after
            </Typography>
            {calculateElapsedPercentage(endDueDate, weekDate) < 100 &&
              calculateElapsedPercentage(endDueDate, weekDate) > 0 && (
                <Button
                  type="button"
                  size="xsmall"
                  style={{ height: '24px', width: 'fit-content' }}
                  priority="primary"
                  className="mt-3"
                  onClick={() => history.push('/pages/payrolls')}
                >
                  Review & Approve
                </Button>
              )}
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Icon
              icon={
                calculateElapsedPercentage(weekDate, cutOffDate) === 100
                  ? success
                  : emptyCircle
              }
            />
            <ProgressBar
              procentage={`${calculateElapsedPercentage(
                weekDate,
                cutOffDate
              )}%`}
              active
            />
            <ProgressBar
              procentage={`${
                100 - calculateElapsedPercentage(weekDate, cutOffDate)
              }%`}
            />
          </div>
          <div className="mt-2 pr-4">
            <Typography className="semibold__16">Payment to you</Typography>
            <Typography className="text_medium__14 mt-2">
              {weekDate.getDate()} {moment(weekDate).format('ddd')} -{' '}
              {cutOffDate.getDate()} {moment(cutOffDate).format('ddd')}
            </Typography>
            <Typography className="text_light__12 color_grey mt-2">
              Payroll managers will review payrolls from day 8 - 21. Your
              invoices will be created when payroll is approved
            </Typography>
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Icon
              icon={
                calculateElapsedPercentage(weekDate, cutOffDate) === 100 &&
                data?.filter((a) => {
                  return a.current.payrolls.some(
                    (payroll) => payroll.state !== 'approved_by_partner'
                  )
                })
                  ? success
                  : emptyCircle
              }
            />
          </div>
          <div className="mt-2 pr-4">
            <Typography className="semibold__16">
              Payroll disbursement
            </Typography>
            <Typography className="text_medium__14 mt-2">
              {cutOffDate.getDate()} {moment(cutOffDate).format('ddd')} -{' '}
              {nextMonthFirstDate.getDate()}{' '}
              {moment(nextMonthFirstDate).format('ddd')}
            </Typography>
            <Typography className="text_light__12 color_grey mt-2">
              Please make sure employees receive salaries on time
            </Typography>
            {calculateElapsedPercentage(weekDate, cutOffDate) === 100 && (
              <Button
                data-testid="CalendarStepper-815E24"
                type="button"
                size="xsmall"
                style={{ height: '24px', width: 'fit-content' }}
                priority="primary"
                className="mt-3"
                onClick={() => history.push('/pages/payslips')}
              >
                Upload payslips
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarStepper
