import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import ChangesAccordion from '@pages/payrollsDetailPage/ChangesAccordion'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'

import {
  getPayrollListCompany,
  getPayrollListNeedChange,
} from '../../services/payroll.service'
import { approvedPayrollsField, payrollsField } from './mock'

export const FlagPayrollCompanyListPage = () => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const activeTab = queryParams.get('tab') || 'pending'

  const { data: listNeedChange, isLoading: changeLoading } = useQuery(
    'payrollsListNeedChange',
    {
      queryFn: () => getPayrollListNeedChange(),
    }
  )

  const { data: companyPayrollList, isFetching } = useQuery(
    ['getPayrollListCompany', activeTab],
    {
      queryFn: () => getPayrollListCompany({ state: activeTab }),
    }
  )
  const handleTabChange = (tab) => {
    queryParams.set('tab', tab)
    history.push({ search: queryParams.toString() })
  }
  const onRowClick = ({ company_id }) => {
    history.push(`/pages/flag_payrolls/${company_id}`)
  }
  const onRowApprovedClick = ({ company_id }) => {
    history.push(`/pages/flag_payrolls/${company_id}/approved`)
  }
  return (
    <div className="invoices-page h-100">
      <PageTitle styleClass="mb-4"> Payroll </PageTitle>
      <Tabs onTabChange={handleTabChange} selectedTab={activeTab}>
        <Tab tabId="pending" title="Pending">
          {isFetching && !companyPayrollList ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <CustomTable
              fields={payrollsField}
              data={companyPayrollList}
              loading={isFetching}
              onRowClick={onRowClick}
            />
          )}
        </Tab>
        <Tab tabId="approved" title="Approved">
          {isFetching && !companyPayrollList ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <CustomTable
              fields={approvedPayrollsField}
              data={companyPayrollList}
              loading={isFetching}
              onRowClick={onRowApprovedClick}
            />
          )}
        </Tab>
        <Tab
          tabId="change_requests"
          title="Change Request"
          count={listNeedChange?.count}
        >
          {changeLoading && listNeedChange?.length === 0 ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div>
              <ChangesAccordion
                month={listNeedChange?.month}
                payments={listNeedChange?.payrolls}
              />
            </div>
          )}
        </Tab>
      </Tabs>
    </div>
  )
}
