import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Toggle from '@atoms/Toggle'
import Typography from '@atoms/Typography/Typography'
import { AttachmentUpload } from '@components/attachment/attachment-upload.component'
import { DOCUMENT_REQUIREMENT } from '@core/constants'
import { attachmentIcon, infoIcon } from '@core/icons/icons'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { AsyncButton } from '@remoteam-front/ui'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

export const RequiredDocumentModal = ({
  title = 'Add Documents',
  submitTitle,
  document,
  onClose,
  onSubmit,
  loading,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: document?.name || '',
      description: document?.description || '',
      requirement: document?.requirement || '',
    },
  })
  const [attachments, setAttachments] = useState(
    document?.attachment ? [document.attachment.file_uuid] : []
  )
  const [attachment, setAttachment] = useState(document?.attachment)

  const handleAttach = (files) => {
    setAttachments(files)
  }

  const handleDelete = (fileId) => {
    setAttachments((prev) => prev.filter((id) => id !== fileId))
  }

  const onBeforeSubmit = (data) => {
    const payload =
      attachments.length > 0
        ? {
            ...data,
            requirement: data.requirement.value || document?.requirement,
            attachments,
            attachment,
          }
        : {
            ...data,
            requirement: data.requirement.value || document?.requirement,
            attachment,
          }
    onSubmit(payload)
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        <Typography>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          display="flex"
          flexDirection="column"
          gap={2}
          id="employeeDocumentsForm"
          onSubmit={handleSubmit(onBeforeSubmit)}
        >
          <div>
            <Input
              data-testid="required-document-modal-6107F7"
              {...register('name', {
                required: 'Name is required',
                maxLength: {
                  value: 200,
                  message: 'Document name must be less than 200 characters',
                },
              })}
              isRequired
              label="Document name"
            />
            {errors.name && (
              <Typography className="text_regular__14 color_red">
                {errors.name.message}
              </Typography>
            )}
          </div>
          <div className="w-100">
            <Controller
              control={control}
              name="requirement"
              rules={{ required: 'Please select requirement' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="required-document-modal-72079F"
                    {...field}
                    label="Requirement"
                    options={DOCUMENT_REQUIREMENT}
                    isClearable
                    isRequired
                  />
                )
              }}
            />
            {errors?.requirement && (
              <Typography className="text_regular__14 color_red">
                {errors?.requirement.message}
              </Typography>
            )}
          </div>
          <div>
            <TextArea
              {...register('description', {
                required: 'Description is required',
              })}
              isRequired
              label="Description"
              maxLength={4000}
              placeholder="What document is this?"
            />
            {errors.description && (
              <Typography className="text_regular__14 color_red">
                {errors.description.message}
              </Typography>
            )}
          </div>
          <Alert severity="info" variant="filled">
            You can upload forms below for new employees to download and fill
            out. They can then upload the completed forms for your approval.
          </Alert>
          {document?.attachments && document.attachments[0]?.file && (
            <a
              data-testid="documents-E9BBE9"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
              }}
              href={document.attachments[0].file}
              rel="noreferrer"
            >
              <Icon fill="none" icon={attachmentIcon} />
              {document.attachments[0].filename}
            </a>
          )}
          {document?.attachment?.file && (
            <a
              data-testid="documents-E9BBE9"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
              }}
              href={document.attachment.file}
              rel="noreferrer"
            >
              <Icon fill="none" icon={attachmentIcon} />
              {document.attachment.filename}
            </a>
          )}
          <div>
            <AttachmentUpload
              accept={{
                'image/*': ['.png', '.jpg', '.jpeg'],
                'application/pdf': ['.pdf'],
              }}
              onAttach={(fileIds) => handleAttach(fileIds)}
              onDelete={(fileId) => handleDelete(fileId)}
              setAttachment={setAttachment}
            />
          </div>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          data-testid="required-document-modal-385D37"
          type="button"
          priority="secondary"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Close
        </Button>
        <AsyncButton
          data-testid="required-document-modal-0C4FFF"
          form="employeeDocumentsForm"
          type="submit"
          isFetching={loading}
          variant="contained"
        >
          {submitTitle}
        </AsyncButton>
      </DialogActions>
    </Dialog>
  )
}
