import { Api } from '@api/Api'

export const getPayrollList = async () => {
  const { data } = await Api.get(`/payrolls/flow/list/`)
  return data
}

export const fetchDeletePayroll = async (id) => {
  const { data } = await Api.post(`/payrolls/${id}/change-state/`, {
    state: 'archived',
  })
  return data
}

export const getPayrollListNeedChange = async () => {
  const { data } = await Api.get(`/payrolls/flow/list/need-changes/`)
  return data
}
export const getPayrollListCompany = async (params) => {
  const { data } = await Api.get(`/payrolls/flow/companies/partner/`, {
    params,
  })
  return data
}
export const getPayrollApprovedAmount = async (params) => {
  const { data } = await Api.get(`/payrolls/get-approved-remaining-amounts/`, {
    params,
  })
  return data
}
export const getPayrollById = async (id) => {
  const { data } = await Api.get(`/payrolls/${id}/`)
  return data
}
export const updatePayrollById = async (id, body) => {
  const { data } = await Api.put(`/payrolls/${id}/update/`, body)
  return data
}
export const approvePayroll = async () => {
  const { data } = await Api.post(`/payrolls/flow/approve/`)
  return data
}
export const partialApprovePayroll = async (body) => {
  const { data } = await Api.post(`/payrolls/flow/partial-approve/`, body)
  return data
}
export const getPayrollByCompanyId = async (id, params) => {
  const { data } = await Api.get(`/payrolls/flow/by-company/${id}/partner`, {
    params,
  })
  return data
}
export const getPayrollInvoiceTotal = async (id) => {
  const { data } = await Api.get(`/payrolls/flow/${id}/totals/`)
  return data
}

export const changePayrollStatus = async (id, body) => {
  const { data } = await Api.post(`/payrolls/${id}/change-status/`, body)
  return data
}

export const fetchExchangeRate = async (body, type) => {
  const { data } = await Api.post(`/payrolls/flow/exchange-rate/`, body)
  return data
}
export const postCalculateLastDay = async (body, type) => {
  const { data } = await Api.post(`/calculate-last-day/`, body)
  return data
}
