import './onboarding-checklist.styles.scss'

import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { check, checklistDocumentIcon } from '@core/icons/icons'
import { Button } from '@mui/material'
import { ComplianceStatus } from '@pages/profile/compliance-tab/compliance-tab'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { useBoolean } from 'usehooks-ts'

import ComplianceModal from './compliance-modal/compliance-modal.component'
import { CountryGuideModal } from './country-guide/country-guide-modal.component'
import { CountryGuideStepper } from './country-guide/country-guide-stepper/country-guide-stepper.component'
import EmployerCostModalComponent from './employer-cost-modal/employer-cost-modal.component'
import { KeyContactsBillingInfoModal } from './key-contacts-billing-info/key-contacts-billing-info-modal.component'
import { KeyContactsBillingInfoStepper } from './key-contacts-billing-info/key-contacts-billing-info-stepper/key-contacts-billing-info-stepper.component'
import SumsubModalComponent from './sumsub-modal/sumsub-modal.component'

export const OnboardingChecklist = ({ passedPercent, checkList }) => {
  const NdaDpaDone = !!checkList.nda_dpa?.passed
  const costCalculatorDone = !!checkList.cost_calculator?.passed
  const complianceDone = !!checkList.compliance?.passed
  const countryStatusDone = !!checkList.country_guide?.passed
  const keyContactsBillingInfoDone =
    !!checkList.key_contacts_billing_info?.passed

  const employerCostDpa = useBoolean(false)
  const openCompliance = useBoolean(false)
  const openSumSub = useBoolean(false)
  const countryGuideDialog = useBoolean(false)
  const countryGuideStepper = useBoolean(false)
  const keyContactsBillingInfoStepper = useBoolean(false)

  const keyContactsBillingInfoDialog = useBoolean(false)

  const steps = [
    ...(NdaDpaDone
      ? [
          {
            title: 'Non-Disclosure & Data Processing Agreements',
            done: NdaDpaDone,
          },
        ]
      : []),
    {
      title: 'Country guide',
      done: countryStatusDone,
      onClick: countryGuideDialog.setTrue,
    },
    {
      title: 'Employer costs',
      done: costCalculatorDone,
      onClick: employerCostDpa.setTrue,
    },
    {
      title: 'Company compliance',
      done: complianceDone,
      status: checkList.compliance?.current_status || 'start',
      onClick: openCompliance.setTrue,
    },
    {
      title: 'Key contacts and billing information',
      done: keyContactsBillingInfoDone,
      onClick: keyContactsBillingInfoDialog.setTrue,
    },
  ]

  const onOpenEmployerCostLink = () => {
    if (checkList.cost_calculator.link) {
      window.open(
        checkList.cost_calculator.link,
        '_blank',
        'noopener,noreferrer'
      )
    }
  }

  const onCompleteCompliance = () => {
    openCompliance.setFalse()
    openSumSub.setTrue()
  }

  return (
    <div className="onboarding-checklist">
      <div className="d-flex align-items-center gap-4">
        <CircularProgressbar
          value={passedPercent}
          strokeWidth={5}
          text={`${Math.ceil(passedPercent)}%`}
          styles={{
            ...buildStyles({
              pathColor: '#04C53A',
              trailColor: 'rgba(38, 40, 66, 0.08)',
            }),
            root: {
              width: '56px',
              height: '56px',
            },
            text: {
              fontWeight: 700,
              fontSize: '25px',
              textAnchor: 'middle',
              dominantBaseline: 'middle',
            },
          }}
        />
        <div>
          <Typography className="heading_semibold__16 onboarding-checklist__title">
            Onboarding checklist
          </Typography>
          <Typography className="text_regular__14 color_grey mt-2">
            You must complete all of the steps to activate your account.
          </Typography>
        </div>
      </div>
      <div className="d-flex flex-column gap-1 mt-3">
        {steps.map((step) => (
          <Step key={step.title} {...step} />
        ))}
      </div>
      {employerCostDpa.value && (
        <EmployerCostModalComponent
          onClose={employerCostDpa.setFalse}
          onSave={onOpenEmployerCostLink}
        />
      )}
      {openCompliance.value && (
        <ComplianceModal
          onClose={openCompliance.setFalse}
          onSave={onCompleteCompliance}
        />
      )}
      {openSumSub.value && (
        <SumsubModalComponent onClose={openSumSub.setFalse} />
      )}
      {countryGuideDialog.value && (
        <CountryGuideModal
          onClose={countryGuideDialog.setFalse}
          onSave={() => {
            countryGuideDialog.setFalse()
            countryGuideStepper.setTrue()
          }}
        />
      )}
      {keyContactsBillingInfoDialog.value && (
        <KeyContactsBillingInfoModal
          onClose={keyContactsBillingInfoDialog.setFalse}
          onSave={() => {
            keyContactsBillingInfoDialog.setFalse()
            keyContactsBillingInfoStepper.setTrue()
          }}
        />
      )}
      {countryGuideStepper.value && (
        <CountryGuideStepper onClose={countryGuideStepper.setFalse} />
      )}
      {keyContactsBillingInfoStepper.value && (
        <KeyContactsBillingInfoStepper
          onClose={keyContactsBillingInfoStepper.setFalse}
        />
      )}
    </div>
  )
}

const Step = ({ title, disabled, onClick, done, status }) => {
  return (
    <div className="step" disabled={disabled} key={title}>
      <div className="step__title">
        <div className="step__icon">
          <Icon icon={checklistDocumentIcon} />
        </div>
        <div>
          <Typography variant="textLarge1">{title}</Typography>
        </div>
      </div>
      {status === 'submitted' && (
        <div className="d-flex gap-1 status status_success">
          <Icon
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            icon={check}
          />
          Submitted
        </div>
      )}
      {status === 'start' ? (
        <Button variant="contained" size="small" onClick={onClick}>
          Start
        </Button>
      ) : (
        status &&
        status !== 'submitted' && (
          <ComplianceStatus status={status || 'start'} />
        )
      )}
      {status === 'retry' && (
        <Button variant="contained" size="small" onClick={onClick}>
          Start
        </Button>
      )}
      {!status &&
        (done ? (
          <div className="d-flex gap-1 status status_success">
            <Icon
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              icon={check}
            />
            Done
          </div>
        ) : (
          <Button variant="contained" size="small" onClick={onClick}>
            Start
          </Button>
        ))}
    </div>
  )
}
