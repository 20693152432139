import './EmployeesExpense.scss'

import Button from '@atoms/Button/Button'
import NotFound from '@atoms/NotFound/NotFound'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Pagination from '@atoms/Pagination/Pagination'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { useToast } from '@core/hooks/useErrorNotification'
import usePagination from '@core/hooks/usePagination'
import { useRouteQuery } from '@core/hooks/useRouteQuery'
import { coin } from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import { last } from 'lodash'
import React, { useState } from 'react'
import { HiDownload } from 'react-icons/hi'
import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

import {
  createExpense,
  getExpenseManagements,
} from '../../services/expense.service'
import { CreateExpenseModal } from './create-expense-modal/create-expense-modal.component'
import DownloadReport from './DownloadReport'
import ExpenseAccordion from './ExpenseAccordion'

export default function EmployeesExpense() {
  const history = useHistory()
  const { successAlert } = useToast()
  const routeQuery = useRouteQuery()
  const { page, limit, setPage } = usePagination({
    page: 1,
    limit: 10,
  })

  const [createdExpenseId, setCreatedExpenseId] = useState(null)
  const [tab, setTab] = useState(0)
  const isOpenDownloadCsv = useBoolean(false)
  const [status, setStatus] = useState(
    '&state_in=PENDING,AWAITING_PAYMENT&is_pending=true'
  )

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['employeeExpense', status, page, limit],
    {
      queryFn: () =>
        getExpenseManagements({ statusExpense: status, limit, page }),
    }
  )

  const onTabChange = (id) => {
    setTab(id)
    setPage(1)
    switch (id) {
      case 0:
        return setStatus('&state_in=PENDING,AWAITING_PAYMENT&is_pending=true')
      case 1:
        return setStatus('&state_in=CANCELLED,PAID,REJECTED')
      default:
        return null
    }
  }

  const newExpenseModalState = useBoolean(
    Boolean(routeQuery.get('openAddModal'))
  )

  const handleModalOpen = () => {
    newExpenseModalState.setTrue()
  }

  const handleModalClose = () => {
    routeQuery.delete('openAddModal')
    history.replace({
      search: routeQuery.toString(),
    })
    newExpenseModalState.setFalse()
  }

  const createExpenseRequest = useMutation({
    mutationFn: (payload) => createExpense(payload),
    onSuccess: (response) => {
      if (response.data) {
        setCreatedExpenseId(last(response.data.id))
      }
      handleModalClose()
      refetch()
      successAlert('Successfully created!')
    },
  })

  const renderTable = React.useMemo(() => {
    return data?.results.length > 0 ? (
      <div className="d-flex flex-column mb-4">
        <div className="d-flex px-3">
          <div className="row expense-table-header w-100">
            <div className="col-2">
              <Typography className="text_regular-normal__14 color_text_300">
                Team member
              </Typography>
            </div>
            <div className="col-2">
              <Typography className="text_regular-normal__14 color_text_300">
                Name
              </Typography>
            </div>
            <div className="col-2">
              <Typography className="text_regular-normal__14 color_text_300">
                Submitted
              </Typography>
            </div>
            <div className="col-1">
              <Typography className="text_regular-normal__14 color_text_300">
                Amount
              </Typography>
            </div>
            <div className="col-2 d-flex">
              <Typography className="text_regular-normal__14 color_text_300">
                Created by
              </Typography>
            </div>
            <div className="col-1 d-flex">
              <Typography className="text_regular-normal__14 color_text_300">
                Expected payout
              </Typography>
            </div>
            <div className="col-2 justify-content-end d-flex">
              <Typography className="text_regular-normal__14 color_text_300">
                Status
              </Typography>
            </div>
          </div>
        </div>
        <div className="">
          <ExpenseAccordion
            createdExpenseId={createdExpenseId}
            expenses={data?.results}
            refetch={refetch}
          />
        </div>
        {data.count && (
          <div className="row align-items-center justify-content-end mt-3">
            <div className="col-auto">
              <Pagination
                total={data.count}
                pageSize={limit}
                page={page}
                onGetPage={setPage}
              />
            </div>
          </div>
        )}
      </div>
    ) : (
      <div>
        <NotFound
          title="It's quiet in here ..."
          action="+ Submit expense"
          onClickAction={handleModalOpen}
        />
      </div>
    )
  }, [data])

  return (
    <div className="employees-time-page">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle> Expenses </PageTitle>

        <div className="d-flex gap-2">
          <div>
            <Button
              data-testid="EmployeesExpense-516689"
              priority="secondary"
              size="small"
              onClick={isOpenDownloadCsv.setTrue}
            >
              <HiDownload className="mr-1" />
              Download report
            </Button>
          </div>
          <div>
            <Button
              data-testid="EmployeesExpense-8B0D76"
              className="ml-2"
              priority="secondary"
              size="small"
              onClick={handleModalOpen}
            >
              Submit expense
            </Button>
          </div>
        </div>
      </div>
      <NotificationBlock
        render={
          <Typography className="text_regular__14 ml-2">
            Manage expense requests from your team.
          </Typography>
        }
        cards={[
          {
            title: 'Submit an expense request',
            description: `Select the “Submit expense” button and create an expense request. The client will then need to approve this before their cut-off date. If it is approved after their cut-off date, it will be automatically added to the next month’s payroll.”`,
            icon: coin,
          },
          {
            title: 'Review expenses requests',
            description:
              'View all expense requests created by clients or employees',
            icon: coin,
          },
        ]}
      />
      <Tabs className="mt-4" onTabChange={onTabChange} selectedTab={tab}>
        <Tab tabId={0} title="Pending">
          {isLoading || isFetching ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div className="expense-table-box">
              <div className="expense-table-wrap">{renderTable}</div>
            </div>
          )}
        </Tab>
        <Tab tabId={1} title="History">
          {isLoading || isFetching ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div className="expense-table-box">
              <div className="expense-table-wrap">{renderTable}</div>
            </div>
          )}
        </Tab>
      </Tabs>

      {newExpenseModalState.value && (
        <CreateExpenseModal
          onClose={handleModalClose}
          loading={createExpenseRequest.isLoading}
          onSubmit={(formData) => createExpenseRequest.mutate(formData)}
        />
      )}

      {isOpenDownloadCsv.value && (
        <DownloadReport onClose={isOpenDownloadCsv.setFalse} />
      )}
    </div>
  )
}
