import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const fetchUserProfile = async () => {
  const v = await Api.get('profile/me/')
  return v.data
}

export const fetchPartnerProfile = generateService(async () => {
  const { data } = await Api.get('partners/profile/my/')
  return data
})

export const fetchCurrencies = async (params) => {
  const v = await Api.get(`currencies/`, { params })
  return v.data
}

export const fetchPopUpMfa = async () => {
  const v = await Api.get('popup/mfa')
  return v.data
}
export const fetchMfaRemind = async () => {
  const v = await Api.post('popup/mfa/')
  return v.data
}
export const fetchMfaCancel = async () => {
  const v = await Api.delete('popup/mfa')
  return v.data
}

export const fetchUpdatePaymenyDetails = async (id, body) => {
  const { data } = await Api.put(`payment-details/${id}/update/`, body)
  return data
}

export const postUserReport = async (data) => {
  const v = await Api.post(`reports/users/create/`, data)
  return v.data
}

export const getResetPassword = async (email) => {
  const v = await Api.post(`password/reset-password/`, {
    email,
  })
  return v.data
}

export const patchContractById = async (id, data) =>
  Api.patch(`contracts/${id}/`, data)

export const fetchUploadFile = async (file, progressCallBack) => {
  const fd = new FormData()
  fd.append('file', file)
  const { data } = await Api.post(`temporary-files/`, fd, {
    onUploadProgress: (progressEvent) => {
      const progress = parseInt(
        Math.round((progressEvent.loaded * 100) / progressEvent.total),
        10
      )
      if (progressCallBack) progressCallBack(progress)
    },
  })
  return data
}

export const getContractById = generateService(async (id) => {
  const { data } = await Api.get(`contracts/${id}`)
  return data
})

export const getOnboardingDocuments = generateService(
  async (contract_id, limit, page) => {
    const offset = (page - 1) * limit
    const { data } = await Api.get(
      `contract-documents-handlers/?limit=${limit}&offset=${offset}&contract_id=${contract_id}`
    )
    return data
  }
)

export const approveDocument = async (documentID) => {
  const { data } = await Api.put(
    `contract-documents-handlers/${documentID}/approve/`
  )
  return data
}
export const declineDocument = async (documentID, body) => {
  const { data } = await Api.put(
    `contract-documents-handlers/${documentID}/decline/`,
    body
  )
  return data
}

export const fetchCompanies = async ({
  state = '',
  search = '',
  page = 1,
  limit = 10,
  country_id,
}) => {
  const offset = (page - 1) * limit
  const query = new URLSearchParams({
    state,
    search,
    limit,
    offset,
    country_id,
  })

  const { data } = await Api.get(`companies/?${query}`)

  return data
}

export const fetchOnboarding = async (id, body) => {
  const { data } = await Api.post(`partners/${id}/onboarding/`, body)
  return data
}

export const fetchContractors = generateService(async (params) => {
  const { data } = await Api.get(`contracts/`, { params })
  return data
})
