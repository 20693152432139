export const payrollsField = (onClickEdit, handleDelete) => [
  {
    title: 'Name',
    key: 'name',
    type: 'textMedium',
    maxWidth: 210,
    minWidth: 210,
    width: 210,
  },
  {
    title: 'Country',
    key: 'country',
    type: 'string',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Gross salary',
    key: 'gross_salary',
    type: 'string',
    maxWidth: 156,
    minWidth: 156,
    width: 156,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
  },
  {
    title: 'Action',
    key: 'action',
    type: 'actions',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
    actions: [
      {
        action: onClickEdit,
        actionText: 'Edit',
        icon: 'edit',
      },
      {
        active: true,
        action: handleDelete,
        icon: 'deleteIcon',
      },
    ],
  },
]
