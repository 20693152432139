export const PAYMENT_FREQUENCY = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'semi_monthly', label: 'Semi-monthly' },
  { value: 'biweekly', label: 'Biweekly' },
]

export const PaymentValue = {
  weekly: 'Weekly',
  monthly: 'Monthly',
  semi_monthly: 'Semi-monthly',
  biweekly: 'Biweekly',
}

export const TEMPLATE_TYPES = {
  JOB_CONTRACT: 'job_contract',
  SALARY_CHANGE: 'salary_change',
  CONTRACT_TERMINATION: 'contract_termination',
  MSA: 'msa',
  APPENDIX: 'appendix',
}

export const EOR_TYPES = {
  TRADITIONAL: 'traditional_eor',
  SAAS: 'saas_eor',
}

export const SIGNATURE_STATES = {
  AWAITING: 'awaiting_signature',
  SIGNED: 'signed',
}

export const PARENTAL_LEAVE_TYPE = {
  MATERNITRY: 'maternity_days',
  PATERNITY: 'paternity_days',
}

export const EMPLOYEE_STATES = {
  ONBOARDING: 'onboarding',
  CREATED: 'created',
  SIGNING: 'signing',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ARCHIVED: 'archived',
  BEING_VERIFIED: 'being_verified',
  COMPLETED: 'completed',
  DRAFT: 'draft',
}

export const PAYROLL_STATUSES = {
  CREATED: 'CREATED',
  APPROVED_BY_COMPANY: 'APPROVED_BY_COMPANY',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  DELETED: 'DELETED',
  MOVED_TO_NEXT_MONTH: 'MOVED_TO_NEXT_MONTH',
}

export const PRONOUNS = {
  SHE_HER: 'she/her',
  HE_HIM: 'he/him',
  THEY_THEM: 'they/them',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
}

export const DOCUMENT_REQUIREMENT = [
  { value: 'mandatory_for_residents', label: 'Mandatory for Residents' },
  { value: 'mandatory_for_nonresidents', label: 'Mandatory for Non-Residents' },
  { value: 'optional_for_everyone', label: 'Optional for Everyone' },
  { value: 'mandatory_for_everyone', label: 'Mandatory for Everyone' },
]

export const TIME_OFF_STATUSES = {
  APPROVED: 'APPROVED',
  CANCELED: 'CANCELED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  TAKEN: 'TAKEN',
}

export const TIME_OFF_STATUSES_MAP = {
  APPROVED: 'Approved',
  CANCELED: 'Canceled',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  TAKEN: 'Taken',
}

export const TIME_OFF_LEAVE_TYPES = {
  VACATION: 'Vacation',
  SICK_LEAVE: 'Sick leave',
  BEREAVEMENT: 'Bereavement',
  PARENTAL_LEAVE: 'Parental leave',
  CAREGIVER_LEAVE: 'Caregiver leave',
  CIVIC_DUTIES: 'Civic duties',
  EDUCATIONAL_LEAVE: 'Educational leave',
  MARRIAGE_LEAVE: 'Marriage leave',
  MILITARY_LEAVE: 'Military leave',
  MILITARY_MEDICAL_EXAMINATION: 'Military medical examination',
  MILITARY_TRAINING_ASSEMBLY: 'Military training assembly',
  RELIGIOUS_OBSERVANCE: 'Religious observance',
  SABBATICAL: 'Sabbatical',
}

export const BALANCE_BREAKDOWN_TYPES = {
  VACATION: 'vacation',
  ADHOC: 'adhoc',
  ANNUAL_ENTITLEMENT: 'annual_entitlement',
  CARRYOVER: 'carryover',
}

export const BALANCE_BREAKDOWN_TYPES_MAP = {
  vacation: 'Vacation',
  adhoc: 'Ad-hoc',
  annual_entitlement: 'Annual entitlement',
  carryover: 'Carryover',
}

export const BALANCE_BREAKDOWN_ACTION_TYPES = {
  USER_GENERATED: 'user_generated',
  AUTO_GENERATED: 'auto_generated',
}

export const BALANCE_BREAKDOWN_ACTION_TYPES_MAP = {
  user_generated: 'User Generated',
  auto_generated: 'Auto Generated',
}

export const USER_ROLES_MAP = {
  company_owner: `Company Admin`,
  'company owner': `Company Admin`,
  company_admin: `Manager`,
  partner_owner: `Partner Admin`,
  partner_manager: `Partner Manager`,
  admin: `System Admin`,
  dummy: `System Admin`,
  employee: `Employee`,
}

export const TimeOffAnnualDaysRule = {
  Statutory: 'statutory',
  Recommended: 'recommended',
  TopOfMarket: 'top_of_market',
}

export const TimeOffAccrualRule = {
  AllAtOnce: 'all_at_once',
  Accrued: 'accrued',
}

export const TimeOffAllowanceRule = {
  ProratedAllowance: 'prorated_allowance',
  FullAllowance: 'full_allowance',
}

export const TimeOffCarryoverRule = {
  Unlimited: 'unlimited',
  Limited: 'limited',
}

export const TimeOffCarryoverExpiry = {
  None: 'none',
  Custom: 'custom',
}

export const TimeOffCarryoverExpiryUnit = {
  Days: 'days',
  Weeks: 'weeks',
  Months: 'months',
  Years: 'years',
}

export const TimeOffSickLeaveDaysRule = {
  Statutory: 'statutory',
  Recommended: 'recommended',
  Unlimited: 'unlimited',
}

export const LeaveType = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
}

export const CONTRACT_JOB_START_DELAY_REASON = {
  client_delay: 'Client delay',
  ee_delay: 'EE delay',
  visa_delay: 'Visa delay',
  deposit_invoice_delay: 'Deposit invoice delay',
  other: 'Other',
}
