import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { deleteIcon, edit, view } from '@core/icons/icons'
import moment from 'moment'
import React from 'react'

export const payrollsField = (onClickView, onClickEdit, handleDelete) => [
  {
    title: 'Team member',
    key: 'name',
    type: 'string',
    maxWidth: 250,
    minWidth: 250,
    width: 250,
  },
  {
    title: 'Submitted',
    key: 'created_at',
    type: 'string',
    maxWidth: 130,
    minWidth: 130,
    width: 130,
  },
  {
    title: 'Company',
    key: 'company',
    type: 'string',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
  },
  {
    title: 'Expected payout',
    key: 'payout_month',
    type: 'custom',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
    render: (item) => {
      return (
        <div className="d-flex align-items-center">
          <Typography className="text_regular__14 mr-2">
            {item?.payout_date ? moment(item.payout_date).format('MMMM') : '-'}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Total amount',
    key: 'total_amount',
    type: 'string',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 120,
    minWidth: 120,
    width: 120,
  },
  {
    title: 'Action',
    key: 'custom',
    type: 'custom',
    maxWidth: 250,
    minWidth: 250,
    width: 250,
    render: (row) => {
      return (
        <div className="d-flex align-items-center">
          <Button
            onClick={() => onClickView(row)}
            className="text_light__12 px-2 pl-0 ml-2"
            priority="secondary"
            size="small"
            style={{ height: '32px' }}
          >
            <Icon fill="none" icon={view} className="mr-2" />
            View
          </Button>
          {row.status !== 'Cancelled' && (
            <Button
              onClick={() => handleDelete(row.id)}
              className="text_light__12 px-2 pl-0 ml-2"
              priority="secondary"
              size="small"
              style={{ height: '32px' }}
            >
              <Icon fill="none" icon={deleteIcon} className="mr-2" />
              Delete
            </Button>
          )}
          {row.status !== 'Cancelled' && (
            <Button
              onClick={() => onClickEdit(row)}
              className="text_light__12 px-2 pl-0 ml-2"
              priority="secondary"
              size="small"
              style={{ height: '32px' }}
            >
              <Icon fill="none" icon={edit} className="mr-2" />
              Edit
            </Button>
          )}
        </div>
      )
    },
  },
]
